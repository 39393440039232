import React from 'react';
import styled from "styled-components";
import ArrowIconSrc from "../../asset/icon__arrow-black.svg";
import {Spacing} from "../pages/MainPage";

const MainGoodItem = ({color, width, height, onClick}) => {
    return (
        <MainGoodItemWrapper onClick={onClick} color={color} width={width} height={height}
                             src={"https://offshoot-content.s3.eu-west-2.amazonaws.com/main/monster_socks_mo.JPG"}>
            <GoodContent>
                Monster Socks
            </GoodContent>
            <Spacing top='288px'/>
            <GoodContent>
                <BuyNowButton onClick={onClick}>
                    DISCOVER MORE DETAILS
                    <img src={ArrowIconSrc} width="56.41px"/>
                </BuyNowButton>
            </GoodContent>
        </MainGoodItemWrapper>
    );
};

export default MainGoodItem;

const MainGoodItemWrapper = styled.div`
  width: 100%;
  height: 375px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  background-image: url(${({src}) => src && src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
`;

const GoodContent = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  padding: 6px;
  align-items: center;
  color: #000;
  background-color: #FB5BC1;
  font-size: 18px;
  font-weight: 450;
  line-height: 20px;
  box-sizing: border-box;
`;

const BuyNowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  color: #000;
  background-color: #FB5BC1;
  font-size: 18px;
  font-weight: 450;
  line-height: 20px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
`;
