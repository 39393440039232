import React from 'react';
import styled from "styled-components";

const CategoryPostItem = ({
                              title,
                              titleStyle,
                              showSubTitle,
                              subTitle,
                              subTitleStyle,
                              width,
                              thumbnailSrc,
                              color,
                              onClick
                          }) => {
    return (
        <CategoryPostWrapper color={color} width={width} onClick={onClick}>
            <Thumbnail src={thumbnailSrc} style={{width: '100%'}}/>
            <CategoryPostContent>
                <Title {...titleStyle}>{title}</Title>
                {showSubTitle && <SubTitle {...subTitleStyle}>{subTitle}</SubTitle>}
            </CategoryPostContent>
        </CategoryPostWrapper>
    );
};

export default CategoryPostItem;

CategoryPostItem.defaultProps = {
    type: 'right',
    showSubTitle: true,
    width: "100%",
    height: "575px",
    titleStyle: {
        color: "#000000",
        size: "20px",
        weight: 450,
    },
    subTitleStyle: {
        color: "#000000",
        size: "15px",
        weight: 400,
    },
}

const Thumbnail = styled.img`
  object-fit: cover;
  width: ${({width}) => width && width};
  height: 345px;
  box-sizing: border-box;
  padding: 15px 0 0 0;
`;

const CategoryPostWrapper = styled.div`
  width: ${({width}) => width && width};
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({color}) => color && `background-color: ${color}`};

  &:hover {
    cursor: pointer;
  }
`;


const CategoryPostContent = styled.div`
  width: ${({thumbnailWidth}) => thumbnailWidth && `calc(100% - ${thumbnailWidth})`};
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 7px 10px;
  box-sizing: border-box;

`;

const Title = styled.div`
  width: 100%;
  color: ${({color}) => color && color};
  font-size: ${({size}) => size && size};
  font-style: normal;
  font-weight: ${({weight}) => weight && weight};
  line-height: 22px;
`;

const SubTitle = styled.div`
  width: 100%;
  color: ${({color}) => color && color};
  font-size: ${({size}) => size && size};
  font-style: normal;
  font-weight: ${({weight}) => weight && weight};
  line-height: 17px;
`;
