import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import LogoHoverImgSrc from "../../../asset/img__logo-black.png";

import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'
import Search, {SearchTab} from "../../component/Search";
import Basket, {BasketContent} from "../../component/Basket";
import Menu, {MenuContent} from "../../component/Menu";
import {useRecoilState} from "recoil";
import {basketState, menuState} from "../../../recoil/atom";
import {useNavigate} from "react-router";
import {ShopContext} from "../../../context/ShopProvider";

export const GNB_TYPE = {
    DEFAULT: 'default',
    GAUGE: 'gauge',
}

const Gnb = ({type}) => {
    const {fetchAllCollections} = useContext(ShopContext);

    const [menuOpen, setMenuOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searchTabOpen, setSearchTabOpen] = useState(false);
    const [basketItem] = useRecoilState(basketState);
    const [selectedMenu, setSelectedMenu] = useRecoilState(menuState);
    const navigate = useNavigate();

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    }

    useEffect(() => {
        fetchAllCollections();
    }, []);

    return (
        <Container>
            <GnbContainer type={type}>
                <ButtonWrapper>
                    <Menu onClick={() => {
                        setMenuOpen(!menuOpen);
                        setSearchTabOpen(false);
                        setDrawerOpen(false);
                    }}/>
                </ButtonWrapper>
                <LogoWrapper
                    onClick={() => {
                        navigate("/");
                        setMenuOpen(false);
                        setSearchTabOpen(false);
                        setDrawerOpen(false);
                    }}>
                    <img src={LogoHoverImgSrc}/>
                </LogoWrapper>
                <ButtonWrapperFlexEnd>
                    <Search onClick={() => setSearchTabOpen(true)}/>
                    <Basket
                        itemCount={basketItem?.length}
                        onClick={() => {
                            setDrawerOpen(true);
                            setMenuOpen(false);
                            setSearchTabOpen(false);
                        }}/>
                </ButtonWrapperFlexEnd>
            </GnbContainer>
            <Drawer
                size={360}
                open={drawerOpen}
                onClose={handleDrawerClose}
                direction='right'
                className='drawer__basket'
            >
                <BasketContent onClose={handleDrawerClose}/>
            </Drawer>
            <MenuContent open={menuOpen} onClose={() => setMenuOpen(false)}/>
            <SearchTab open={searchTabOpen} onClose={() => setSearchTabOpen(false)}/>
        </Container>
    )
};

export default Gnb;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100vw;
`;

const GnbContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 62px;
  box-sizing: border-box;
  padding: 0 20px;

  z-index: 5;
  position: fixed;
  ${({type}) => type === GNB_TYPE.DEFAULT ? 'background-color:#000000' : 'background-color:none'};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  height: 32px;
  gap: 4px;
`;

const ButtonWrapperFlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100px;
  height: 32px;
  gap: 4px;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 133px;
  height: 28px;
`;
