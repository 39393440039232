import React from 'react';
import styled from "styled-components";
import RedArrowIconSrc from "../../asset/icon__arrow-red.svg";

const MainGoodItem = ({src, color, width, height, name, img, onClick}) => {
    return (
        <MainGoodItemWrapper onClick={onClick} color={color} width={width} height={height} src={img}>
            <GoodContent>
                {name}
            </GoodContent>
            <GoodContent>
                <BuyNowButton>
                    LEARN MORE
                    <FlexBoxRow>
                        <img src={RedArrowIconSrc} width="15px" style={{marginLeft: "5px"}}/>
                        <img src={RedArrowIconSrc} width="15px" style={{marginLeft: "5px"}}/>
                        <img src={RedArrowIconSrc} width="15px" style={{marginLeft: "5px"}}/>
                        <img src={RedArrowIconSrc} width="15px" style={{marginLeft: "5px"}}/>
                    </FlexBoxRow>
                </BuyNowButton>
            </GoodContent>
            <img src={src} width={"100%"}/>
        </MainGoodItemWrapper>
    );
};

export default MainGoodItem;

const FlexBoxRow = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const MainGoodItemWrapper = styled.div`
  width: 100%;
  height: ${({height}) => height && height};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  background-image: url(${({src}) => src && src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
`;

const GoodContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #C02126;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

const BuyNowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  color: #C02126;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  box-sizing: border-box;
  padding: 0 5px;
  
  &:hover {
    cursor: pointer;
  }
`;
