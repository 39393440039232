import React, {useEffect, useRef, useState, useCallback} from 'react';
import OpenIconSrc from '../../asset/icon__open.png'
import styled from "styled-components";

const Dropdown = ({selectedItem, items, onClick}) => {
    const [open, setOpen] = useState();
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);


    const handleLabelClick = () => {
        setOpen(!open);
    }
    const handleDropdownItemClick = useCallback((item) => {
        onClick && onClick(item);
        setOpen(!open);
    }, [onClick, open])

    return (
        <DropdownWrapper ref={ref}>
            <DropdownLabel
                label={selectedItem.label}
                open={open}
                onClick={handleLabelClick}
            />
            {open && <DropdownItems onClick={handleDropdownItemClick} items={items}/>}
        </DropdownWrapper>
    );
};

export default Dropdown;

const DropdownLabel = ({label, open, onClick}) => {
    return <DropdownLabelWrapper onClick={onClick}>
        {label}
        <DropdownButton src={OpenIconSrc} open={open}/>
    </DropdownLabelWrapper>;
}

const DropdownItems = ({items, onClick}) => {

    const handleClickDropdownItem = (item) => {
        onClick && onClick(item);
    }

    return <DropdownItemsWrapper>
        {items.map((item) =>
            <DropdownItem key={item.key} onClick={() => handleClickDropdownItem(item)}>{item.label}</DropdownItem>
        )}
    </DropdownItemsWrapper>;
}

const DropdownWrapper = styled.div`
  width: 70px;
  height: fit-content;
`;

const DropdownLabelWrapper = styled.div`
  width: 70px;
  height: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #000000;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0 0 6px 3px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const DropdownButton = styled.img`
  src: ${({src}) => src && src};
  width: 10px;
  height: 10px;
  ${({open}) => open && `transform: scaleY(-1)`};
`;

const DropdownItemsWrapper = styled.div`
  position: absolute;
  z-index: 5;
  width: 70px;
  min-height: 40px;
  box-sizing: border-box;
  //border: 1px solid #000000;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const DropdownItem = styled.div`
  height: 20px;
  padding: 6px 0 6px 3px;
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: rgba(0,0,0,0.4);
  }
`;
