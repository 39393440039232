import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Outlet, useLocation} from "react-router-dom";
import Contents from "./layout/Contents";
import Gnb from "./layout/Gnb";
import {Spacing} from "./MainPage";
import MainVideoSrc from "../../asset/offshootdot.mp4";
import {menuState, playVideoState} from "../../recoil/atom";
import {useRecoilState} from "recoil";

const HomePage = () => {
    const [playVideo, setPlayVideo] = useRecoilState(playVideoState);
    const [, setSelectedMenu] = useRecoilState(menuState);

    const {pathname} = useLocation();

    useEffect(() => {
        setSelectedMenu(pathname);
    }, [pathname, setSelectedMenu])

    useEffect(() => {
        const handleVideoEnd = (e) => {
            const element = document.querySelector('.video_obj');
            element.animate(
                [
                    {transform: "rotate(0) scale(1)"},
                    {transform: "rotate(360deg) scale(0)"},
                ],
                {
                    durations: 1800,  // 밀리초 지정
                    iterations: 1,
                    // fill: 'forwards',  // 종료 시 속성을 지님
                    // easing: 'ease'  // 가속도 종류
                }
            );
            setTimeout(() => setPlayVideo(false), 2000);
        }
        document.getElementById('video_obj')?.addEventListener('ended', handleVideoEnd, false);
    }, [setPlayVideo, playVideo]);

    return (
        <Container>
            {pathname.split("/").length === 2 && playVideo &&
            <Background onClick={() => setPlayVideo(false)}>
                <video className="video_obj" id={'video_obj'} width="fit-content" autoPlay muted playsInline
                       preload="auto">
                    <source src={MainVideoSrc} type="video/mp4"/>
                </video>
            </Background>}
            <Body>
                <Gnb/>
                <Spacing top="80px"/>
                <Contents>
                    <Outlet/>
                </Contents>
            </Body>
        </Container>
    );
};

export default HomePage;
const Background = styled.div`
  position: absolute;
  top: 0;
  width: calc(max(100vw, 1630px));
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: black;
  object-fit: none;
`;

const Container = styled.div`
  max-height: 100vh;
  display: flex;
  max-width: 100vw;
  align-items: flex-start;
  overflow: hidden;
  font-family: 'Neue Haas Thin';
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  font-family: 'Neue Haas Thin';

  & > #contents-utility {
    align-self: stretch;
    marin-top: 50px;
  }
`
