import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useNavigate} from "react-router";
import ImgSrc from "../../asset/img__main-shop.png"
import BannerImgSrc from "../../asset/img__banner.png"
import Banner from "../component/Banner";
import {mainPosts} from "../../mockData";
import MainCategoryPostItem from "../component/MainCategoryPostItem";
import MainVideoSrc from "../../asset/offshoot.mp4";
import MainTeaserSrc from "../../asset/offshootdot-main.mp4"
import MainGoodItem from "../component/MainGoodItem";
import Modal from "react-modal";
import CloseIconSrc from "../../asset/icon__close.png";
import {useRecoilState} from "recoil";
import {collectionState, menuState, showModalState, subMenuState} from "../../recoil/atom";
import {newsLetterApi} from "../../api/newsLetter/NewsLetterApi";
import HolidayImgSrc from "../../asset/mock/main/holiday-main-mo.png";
import BowImgSrc from "../../asset/bow.gif";
import RedCloseIconSrc from "../../asset/icon__close-big.svg";
import ArrowIconSrc from "../../asset/icon__arrow-white.svg";
import MainSocksItem from "../component/MainSocksItem";
import GoodImg from "../../asset/mock/main/lookbook-23fw-collection-mo.png";
import RootGoodImg from "../../asset/mock/main/lookbook-root-main-mo.png";
import RwwoGoodImg from "../../asset/mock/main/lookbook-rwwo-main-mo.png";
import NewMainCategoryPostItem from "../component/NewMainCategoryPostItem";

const MainPage = () => {
    useEffect(() => {
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        setScreenSize();
        window.addEventListener('resize', setScreenSize);

        return () => window.removeEventListener('resize', setScreenSize);
    }, []);

    const [, setSelectedMenu] = useRecoilState(menuState);
    const [, setSelectedSubMenu] = useRecoilState(subMenuState);
    const [, setSelectedCollection] = useRecoilState(collectionState);
    const [showModal, setShowModal] = useRecoilState(showModalState);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalGuideMsg, setModalGuideMsg] = useState('Check your email again.');
    const [value, setValue] = useState('');

    const navigate = useNavigate();
    const handlePostClick = (id) => {
        navigate(`/magazine/${id}`);
    }

    const handleClickSubscribeButton = async () => {
        if (value?.split("@").length === 2) {
            const id = value.split("@")[0];
            const domain = value.split("@")[1];
            try {
                await newsLetterApi.subscribe({id, domain});
                setModalGuideMsg('Subscription completed.');
                setModalOpen(true);
                setShowModal(false);
                setTimeout(() => setModalOpen(false), 1000);
                setValue("");
            } catch (e) {
                console.log(e)
            }
        } else {
            setModalGuideMsg('Please check the input value');
            setModalOpen(true);
            setTimeout(() => setModalOpen(false), 1000);
        }
    };

    const images = [{id: 0, image: BannerImgSrc}, {id: 1, image: BannerImgSrc}];
    return (
        <Container>
            <Background>
                <video style={{objectFit: 'cover'}} height="100%" autoPlay muted playsInline loop
                       preload="auto">
                    <source src={MainVideoSrc} type="video/mp4"/>
                </video>
            </Background>
            <Filter/>
            <ContentsWrapperColumn>
                <VideoWrapper>
                    <video width="100%" style={{maxHeight: "100%", maxWidth: "100%", objectFit: "cover"}} autoPlay muted
                           playsInline controls loop preload="auto">
                        <source src={MainTeaserSrc} type="video/mp4"/>
                    </video>
                </VideoWrapper>
                {/*<Banner images={images} speed={180000}/>*/}
                <Spacing top="15px"/>
                <ImageWrapper onClick={() => {
                    window.open("https://shop.offshootdot.com");
                }}>
                    <FlexBoxRow style={{
                        width: "100%",
                        height: "44px",
                        boxSizing: "border-box",
                        padding: "0 16px",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        SHOP
                        <FlexBoxRow>
                            <img src={ArrowIconSrc} width="15px" style={{marginLeft: "5px"}}/>
                            <img src={ArrowIconSrc} width="15px" style={{marginLeft: "5px"}}/>
                            <img src={ArrowIconSrc} width="15px" style={{marginLeft: "5px"}}/>
                            <img src={ArrowIconSrc} width="15px" style={{marginLeft: "5px"}}/>
                            <img src={ArrowIconSrc} width="15px" style={{marginLeft: "5px"}}/>
                            <img src={ArrowIconSrc} width="15px" style={{marginLeft: "5px"}}/>
                        </FlexBoxRow>
                    </FlexBoxRow>
                    <img src={ImgSrc} width="100%" height="100%"/>
                </ImageWrapper>
            </ContentsWrapperColumn>

            <Spacing top="35px"/>
            <ContentsWrapperColumn>
                <NewMainCategoryPostItem
                    title={mainPosts[2].title}
                    titleStyle={{color: "#fff", size: "20px", weight: "450"}}
                    subTitle={mainPosts[2].subTitle}
                    subTitleStyle={{color: "#fff", size: "16px", weight: "400"}}
                    width="100%" thumbnailWidth="0%"
                    height="60vh"
                    color={mainPosts[2].color}
                    thumbnailSrc={mainPosts[2].basicThumbnail}
                    showSubTitle
                    onClick={() => handlePostClick(mainPosts[2].id)}
                />
                <NewMainCategoryPostItem
                    title={mainPosts[0].title}
                    titleStyle={{color: "#ffffff", size: "20px", weight: "450"}}
                    subTitle={mainPosts[0].subTitle}
                    subTitleStyle={{color: "#ffffff", size: "16px", weight: "400"}}
                    width="100%" thumbnailWidth="0%"
                    height="60vh"
                    color={mainPosts[0].color}
                    // thumbnailSrc={"https://offshoot-content.s3.eu-west-2.amazonaws.com/main/post1_mo.jpeg"}
                    thumbnailSrc={mainPosts[0].basicThumbnail}
                    showSubTitle
                    onClick={() => handlePostClick(mainPosts[0].id)}
                />
                <NewMainCategoryPostItem
                    title={mainPosts[1].title}
                    titleStyle={{color: "#000", size: "20px", weight: "450"}}
                    subTitle={mainPosts[1].subTitle}
                    subTitleStyle={{color: "#000", size: "16px", weight: "400"}}
                    width="100%" thumbnailWidth="0%"
                    height="60vh"
                    color={mainPosts[1].color}
                    // thumbnailSrc={"https://offshoot-content.s3.eu-west-2.amazonaws.com/main/post2_mo.PNG"}
                    thumbnailSrc={mainPosts[1].categoryThumbnail}
                    showSubTitle
                    onClick={() => handlePostClick(mainPosts[1].id)}
                />
            </ContentsWrapperColumn>
            <Spacing top="35px"/>

            <ContentsWrapperColumn>
                <MainSocksItem onClick={() => window.open("https://shop.offshootdot.com/collections/socks")}/>
            </ContentsWrapperColumn>
            <Spacing top="35px"/>

            <ContentsWrapperRow>
                <MainGoodItem
                    src={RwwoGoodImg}
                    onClick={() => {
                        setSelectedMenu("/shop");
                        setSelectedSubMenu("collection");
                        setSelectedCollection('25-ㄱㅈㅈㅇ');
                        navigate(`/shop/collection/25-ㄱㅈㅈㅇ`);
                    }}
                    width="100%" height="515px" name="OFFSHOOT 2025 ㄱㅈㅈㅇ COLLECTION"/>
            </ContentsWrapperRow>
            <ContentsWrapperRow>
                <MainGoodItem
                    src={RootGoodImg}
                    onClick={() => {
                        setSelectedMenu("/shop");
                        setSelectedSubMenu("collection");
                        setSelectedCollection('24-root-1');
                        navigate(`/shop/collection/24-root-1`);
                    }}
                    width="100%" height="485px" name="OFFSHOOT 2024 ROOT COLLECTION"/>
            </ContentsWrapperRow>
            <ContentsWrapperRow>
                <MainGoodItem
                    src={GoodImg}
                    onClick={() => {
                        setSelectedMenu("/shop");
                        setSelectedSubMenu("collection");
                        setSelectedCollection('23-fw-taboo');
                        navigate(`/shop/collection/23-fw-taboo`);
                    }}
                    width="100%" height="515px" name="OFFSHOOT 2023 TABOO COLLECTION"/>
            </ContentsWrapperRow>
            <Spacing top="35px"/>
            <ContentsWrapperColumn>
                <MainCategoryPostItem
                    width="100%" thumbnailWidth="0%"
                    height="440px"
                    thumbnailSrc={HolidayImgSrc}
                    onClick={() => {
                        setSelectedMenu("/shop/3d");
                        setSelectedCollection("holiday");
                        setSelectedSubMenu("collection");
                        navigate(`/shop/3d/collection/holiday`);
                    }}
                />
                <Spacing top="75px"/>
            </ContentsWrapperColumn>

            <Modal style={{
                content: {
                    width: "56vw",
                    height: "135vw",
                    border: "none",
                    borderRadius: "0",
                    backgroundColor: "#C02126",
                    color: "#C02126",
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '10px 10px',
                    boxSizing: 'border-box',
                },
                overlay: {
                    display: 'contents',
                    top: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                },
            }}
                   isOpen={showModal}>
                <NewsLetterModalContent>
                    <FlexBoxColumn style={{justifyContent: "center"}}>
                        <FlexBoxRow>
                            <FlexBoxRow style={{
                                lineHeight: '24px',
                                fontSize: '22px',
                                fontWeight: '450'
                            }}>NEWSLETTER</FlexBoxRow>
                        </FlexBoxRow>
                        <Spacing top="6px"/>
                        <FlexBoxRow style={{fontSize: '16px', lineHeight: '20px', fontWeight: '450'}}>
                            Sign up for monthly<br/>dispatches on fashion<br/>and fine art from the<br/>OFFSHOOT
                        </FlexBoxRow>
                    </FlexBoxColumn>
                    <FlexBoxColumn>
                        <FlexBoxColumn>
                            <EmailInput value={value} onChange={(e) => setValue(e.target.value)}
                                        placeholder="Your Email"/>
                            <Spacing top="7px"/>
                            <SubscribeButton onClick={handleClickSubscribeButton}>Subscribe</SubscribeButton>
                        </FlexBoxColumn>
                        <Spacing top="11.4px"/>
                        <BigCloseButton src={RedCloseIconSrc} width="95px" onClick={() => setShowModal(false)}/>
                    </FlexBoxColumn>
                </NewsLetterModalContent>
            </Modal>
            <Modal style={{
                content: {
                    width: "80vw",
                    top: '80%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                },
                overlay: {
                    // display: 'none'
                },
            }}
                   isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
                <ModalContent style={{flexDirection: 'row'}}>
                    {modalGuideMsg}
                    <CloseButton src={CloseIconSrc} width="20px" height="20px" onClick={() => setModalOpen(false)}/>
                </ModalContent>
            </Modal>
        </Container>
    );
};

export default MainPage;

const Background = styled.div`
  position: fixed;
  top: 50px;
  right: 0;
  width: 100vw;
  //height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: -2;
  background-color: rgba(255, 255, 255, 1);
  background-size: cover;
`;
const Filter = styled.div`
  position: fixed;
  top: 50px;
  width: 100vw;
  //min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  z-index: -2;
  background-color: white;
  mix-blend-mode: difference;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  //height: calc(100vh - 150px);
  height: calc(calc(var(--vh, 1vh) * 100) - 150px);
  flex-direction: column;
  align-items: center;
`;

export const Spacing = styled.div`
  padding-top: ${({top}) => top || '0'};
`;

const ContentsWrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  align-items: center;
`;

const ContentsWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: center;
  align-items: center;
`;

const VideoWrapper = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  background: #000000;
`;

const ImageWrapper = styled.div`
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: #C02126;
  color: #fff;
  font-weight: 600;
  font-size: 23px;
  line-height: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const NewsLetterModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 21px 25px;
  background-color: #fff;
  background-image: url(${BowImgSrc});
  background-size: cover;
  background-position: left -140px top -10px;
  background-repeat: no-repeat;
`;

const BigCloseButton = styled.img`
  display: flex;
  width: 37px;
  justify-content: flex-end;
  align-items: flex-end;

  &:hover {
    cursor: pointer;
  }
`;

const FlexBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FlexBoxColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const EmailInput = styled.input`
  width: 100%;
  height: 26px;
  background: #ffffff;
  font-size: 18px;
  font-family: inherit;
  line-height: 21px;
  font-weight: 450;
  box-sizing: border-box;
  border: 3px solid #C02126;
  position: relative;
  text-align: center;
  outline: none;

  &::placeholder {
    color: #C02126;
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

const SubscribeButton = styled.div`
  background: #C02126;
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #FFF;
  font-size: 18px;
  font-family: inherit;
  line-height: 21px;
  font-weight: 450;

  &:hover {
    cursor: pointer;
  }
`;
