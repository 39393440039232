export const mainPosts = [
    {
        id: 1,
        category: 'fashion',
        title: "History of Western knitwear",
        subTitle: "Not only in the avante-garde but amongst the wider fashion zeitgeist the boundaries of knitwear are ceasing to be, coalescing with every kind of body to become a second skin.",
        color: "#0047FF",
        createDate: new Date(2024,2,2),
        basicThumbnail: 'https://offshoot-content.s3.eu-west-2.amazonaws.com/post/1/post1_embed13.JPG',
        categoryThumbnail: 'https://offshoot-content.s3.eu-west-2.amazonaws.com/post/1/post1_embed13.JPG',
    },
    {
        id: 2,
        category: 'art',
        title: "Weathered Hands",
        subTitle: "Shouldn't we, as contemporary individuals witnessing this transformation, express reverence towards those weathered hands that labored for our benefit?",
        color: "#83EA1B",
        createDate: new Date(2024,1,28),
        basicThumbnail: 'https://offshoot-content.s3.eu-west-2.amazonaws.com/post/2/post2_embed4.png',
        categoryThumbnail: 'https://offshoot-content.s3.eu-west-2.amazonaws.com/post/2/post2_embed8.png',
    },
    {
        id: 3,
        category: 'art',
        title: "Emotional studies on isolation and solidarity",
        subTitle: "In its entirety, the Taboo collection serves as a narrative exploration of individuality, challenging taboos and celebrating the beauty of self-expression.",
        color: "#000000",
        createDate: new Date(2024,3,6),
        basicThumbnail: 'https://offshoot-content.s3.eu-west-2.amazonaws.com/post/3/post3_thumbnail.png',
        categoryThumbnail: 'https://offshoot-content.s3.eu-west-2.amazonaws.com/post/3/post3_thumbnail.png',
    },
];
